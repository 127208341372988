import ElMessage from 'element-plus'
const tools = { /** 
    * 判断arr数组中是否存在p1
    * @param {*} p1 检查参数
    * @param {Array} arr 源数组
    * @returns boolean
    */
    inArray: function (p1, arr) {
        let isExists = false;
        arr.forEach(item => {
            if (item == p1) {
                isExists = true
                return
            }
        })

        return isExists;
    },
    ucFirst: function (params) {
        var str = params.toLowerCase();
        var strArr = str.split(' ');
        var result = '';
        for (var i in strArr) {
            result += strArr[i].substring(0, 1).toUpperCase() + strArr[i].substring(1) + ' ';
        }
        return result;
    },
    timeFormat(time, format = 'date') {
        const symbolType = '-'

        const date = new Date(time)
        const Y = this.getFullTime(date.getFullYear())
        const m = this.getFullTime(date.getMonth())
        const d = this.getFullTime(date.getDay())
        const H = this.getFullTime(date.getHours())
        const i = this.getFullTime(date.getSeconds())
        const s = this.getFullTime(date.getSeconds())

        if (format == "date") {
            return Y + symbolType + m + symbolType + d
        }

        if (format == 'datetime') {
            return Y + symbolType + m + symbolType + d + " " + H + ":" + i + ":" + s
        }


    },
    getFullTime(time) {
        return time < 10 ? "0" + time.toString() : time.toString()
    },
    copyWord(word) {
        let tag = document.createElement('textarea');
        tag.setAttribute('id', 'cp_hgz_input');
        tag.value = word;
        document.getElementsByTagName('body')[0].appendChild(tag);
        document.getElementById('cp_hgz_input').select();
        document.execCommand('copy');
        document.getElementById('cp_hgz_input').remove();
        ElMessage({ message: '复制成功', type: 'success' })
    }
};

export default tools;
