const token = {
    get: function () {
        return JSON.parse(sessionStorage.getItem('token'))
    },
    /**
     * 检查是否登录，是否登录过期
     */
    check: function (router) {
        const token = this.get()
        if (!token) {
            router.push({ path: '/login' })
            return
        }

        const currentTime = Number((Date.parse(new Date()) / 1000))
        const expiresTime = (Number(token.begin_time) + Number(token.expires_in))
        if (currentTime > expiresTime) {
            router.push({ path: '/login' })
        }

    },
    /**
     * 更新过期时间
     */
    updateExpiresIn: function () {
        const token = this.get()
        if (token) {
            token.begin_time = (Date.parse(new Date()) / 1000)
            sessionStorage.setItem('token', JSON.stringify(token));
        }
    }
}

export default token