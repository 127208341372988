<template>
  <router-view></router-view>
</template>

<style lang="less">
.form-box {
  margin-bottom: 35px;
}

.table-head {
  padding-bottom: 25px;

  .table-title {
    font-size: 18px;
  }

  .table-btn {
    float: right;
  }
}
</style>