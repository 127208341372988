import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './path'
import pathTools from '../tools/pathTools'
import tools from '../tools/tools'
import { store } from '../common/store'
import storeTools from '../tools/storeTools'
import token from '../tools/tokenTools'
// import http from '@/request/request'

const path = pathTools.putPath(routes)
// 组装path文件中路径
const routeArr = pathTools.pathDelayer(path)

// 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({ // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes: routes, // `routes: routes` 的缩写
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/resetPassword') {
        next() // 不经过路由守卫，直接导航到/resetPassword
    } else {
        if (tools.inArray(to.path, routeArr)) {
            if (to.path !== '/login') {
                token.check(router)
            }

            const breadcrumbNames = setBreadcrumbNames(to)

            // 设置网页title
            if (to.matched.length === 1) {
                document.title = breadcrumbNames[0]
            } else {
                document.title = breadcrumbNames[0] + '/' + breadcrumbNames[1]
                setTabs(to)
            }
            next()
        } else {
            console.log(from.path)
            router.push({ path: '/404' })
        }
    }
})

// 全局解析守卫
// router.beforeResolve(async to => {
//     console.log(to)
// })

// 全局后置钩子
router.afterEach(() => {
    token.updateExpiresIn()
})


/**
 * 把每次请求都放进一个数组，用来显示tab标签也
 */
function setTabs(to) {
    const pathArr = to.matched[1].path.split('/')
    let name = ''
    switch (pathArr.length) {
        case 2: name = pathArr[1]
            break;
        case 3: name = pathArr[1] + '_' + pathArr[2]
            break;
    }
    const newTabs = storeTools.setTabs(to.matched[1].name, name, to.matched[1].path)

    // 当前tab标签选中项
    for (let i = 0; i < newTabs.length; i++) {
        if (newTabs[i].path == to.matched[1].path) {
            store.setIsActive(i + "-" + to.matched[1].path)
        }
    }
}

/**
 * 每次请求都重新获取路由的名称，用于设置header中的路径面包屑
 */
function setBreadcrumbNames(to) {
    const menuItems = pathTools.loadMenuItem()
    const firstMenuIndex = to.path.split('/')[1]

    let secondMenuIndex = to.path.split('/')[2]
    let firstMenu = menuItems[firstMenuIndex]

    if (!firstMenu) {
        firstMenu = menuItems['']
        secondMenuIndex = firstMenuIndex
    }

    const firstMenuName = firstMenu.name
    const secondMenuName = firstMenu['children'][secondMenuIndex]
    const breadcrumbNames = [firstMenuName, secondMenuName]
    store.setBreadcrumbNames(breadcrumbNames)

    return breadcrumbNames
}

export default router
